// Theme Colors/Branding
$brand-primary: #0e1a88;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #cc1d18;
$dark: darken($brand-primary, 10%);
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #fddc12;
$accent-2: #1459BA;
$body-font: #222;
$error: #d81e00;

// Update BS colors
$primary: $brand-primary;
$secondary: $brand-secondary;
$info: $accent;