
.home-page-reviews {
	padding-top:2.5rem;
	margin-top: 0;
	@include media-breakpoint-up(xl) {
		margin-top:-75px;
	}
}

.rpb-customer-reviews {
    .customer-reviews-slider {
		.splide__track {
			margin-left: 1rem!important;
			margin-right: 1rem!important;
			padding-bottom: 15px!important;
			@include media-breakpoint-up(xl) {
				margin-left: -22rem!important;
				margin-right: -22rem!important;
			}
		  }
		}
		.splide__arrow {
			border-radius: 0!important;
		 }
        .splide__arrows {
            @include media-breakpoint-up(lg) {
                position: absolute;
                right: auto;
				top: -70px;
				width: 165px;
				right: 0;
                left: auto;
            }
            @include media-breakpoint-up(lg) {
             background: $brand-secondary;
            .splide__arrow {
                background: $brand-secondary!important;
                box-shadow: none!important;
                font-size: 1.5rem!important;
				border-radius: 0!important;
				transition: all 0.25s;
                i,svg {
                    color: #fff!important;
                }
				&:hover {
					background: darken($brand-secondary, 10%)!important;
				}
            }
         }
         .splide__arrow--next {
            @include media-breakpoint-down(lg) {
                right: -5px!important;
            }
          }
          .splide__arrow--prev {
            @include media-breakpoint-down(lg) {
                left: -5px!important;
            }
          }
        }
		.splide__pagination {
			bottom: -1rem;
		}
		.location-single-review {
			background: $dark;
			position: relative;
			color: #fff;
			border: none!important;
			border-radius: 6px;
			&:before {
				content:'';
				position:absolute;
				left: 0;
				top: 0;
				background: linear-gradient(135deg, rgba(255,255,255,0.07) 0%,rgba(255,255,255,0) 35%);
				height: 100%;
				width: 100%;
			}
			.location-single-review-content {
				height: 105px;
				overflow-y: scroll;
				scrollbar-color: rgba(0,0,0,0.9) $brand-secondary;
				scrollbar-width: thin;
				p {
					padding-right: 1.65rem;
					font-size: 0.9rem;
				}
			}
		 }
}

.first-content-area {
	.hc-half-content-image {
		border-radius: 0 12px 12px 0;
	}
}

.second-content-area {
	.hc-half-content-image {
		border-radius: 12px 0 0 12px
	}
}

.rpb-featured-cards {
	.splide__track {
		margin-left: 1rem!important;
		margin-right: 1rem!important;
		padding-bottom: 15px!important;
		@include media-breakpoint-up(xl) {
			margin-left: -15rem!important;
			margin-right: -15rem!important;
		}
	}
	.splide__arrow {
		background: transparent!important;
		box-shadow: none!important;
		i,svg {
			color: #fff!important;
		}
	}
	.splide__arrow--prev {
		left: 1rem!important;
	}
	.splide__arrow--next {
		right:1rem!important;
	}

	.btn {
		letter-spacing: 0;
		padding: 0.75rem 1rem;
		color: #fff!important;
		background: transparent!important;
	}
	.icon-wrap {
		position: absolute;
		right: 0;
		height: 100%;
		width: 50px;
		display: flex;
		align-items: center;
		color: #fff;
		background: transparent;
		justify-content: center;
	}
	.card {
		position:relative;
		&:after {
			content:'';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%,rgba(0, 0, 0, 0.25) 100%);
			@extend .rounded;
		}
	}

	.shrink-hover {
		transition: all 0.2s ease; 
		&:hover {
			transform: scale(0.95);
			box-shadow: 0 0 8px rgba(0,0,0,0.25);
		}
	}
	
}

.home-featured-content {
	 .splide__slider .rpb-slider-arrows .splide__arrow {
		background: transparent!important; 
		i,svg {
			color: lighten($brand-secondary, 15%)!important;
		}
	}
	.range-content-slider .splide__slider .splide__track {
		margin-left: 1rem!important;
		margin-right: 1rem!important;
		@include media-breakpoint-up(xl) {
			margin-left: 3rem!important;
			margin-right: 3rem!important;
		}
	}
	.splide__arrow--prev {
		left: -25px!important;
		@include media-breakpoint-up(xl) {
			left: 0!important;
		}
	}
	.splide__arrow--next {
		right: -25px!important;
		@include media-breakpoint-up(xl) {
			right:0!important;
		}
	}

	.sectionTech {
		position: absolute;
		right: 12.5%;
		bottom: 0;
		max-height: 650px;
		filter: drop-shadow(0 3mm 4mm rgba(0,0,0,.5));
		z-index: 1;
		transform: scale(-1, 1);
		@include media-breakpoint-up(xl) {
			max-height: 700px;
		}
	}
}

.footer-cta {
	img.footerHvac {
		max-height: 185px;
		margin: 0 auto 1.5rem;
		@include media-breakpoint-up(xl) {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		top: -90px;
		max-height: 250px;
		}
	}
}

#logo-banner {
	.logo-banner-single {
		img {
			max-height: 75px;
			width: auto;
		}
	}
}